$(function(){
    /** .tile-ingenitron click handling **/
    $('body').on('click','.clickGoTo',function(e){
        e.preventDefault();
        if ($(this).attr('data-href')) {
            window.location = $(this).data('href');
        }
    });
});

$(document).delegate('*[data-toggle="lightbox"]', 'click', function(e) {
    e.preventDefault();
    $(this).ekkoLightbox();
});